
































































































































































import { Fornecedor, Funcionario } from "@/core/models/geral";
import { Equipamento, PedidoCompra, RegistroOperacional } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { FornecedorService, FuncionarioService } from "@/core/services/geral";
import { EquipamentoService, PedidoCompraService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaRegistroOperacional extends PageBase {
    search: any = '';
    overlay: boolean = false;
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    valid = true;
    options: any = {
        itemsPerPage: 15
    };
    $refs!:{
        form: HTMLFormElement
    }

    tipoUsuario = EnumTipoUsuario;

    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'numero', type:'number'},
        { text: 'Fornecedor', value: 'fornecedor.nome'},
        { text: 'Equipamento', value: 'equipamento.nome'},
        { text: 'Responsável', value: 'responsavel.nome'}
    ];

    item = new PedidoCompra();
    service = new PedidoCompraService();

    filtro = {
        numero: null,
        numeroRo: null,
        numeroOs: null,
        responsavelId: null,
        equipamentoId: null,
        fornecedorId: null,
        dataHoraSolicitacaoInicial: null,
        dataHoraSolicitacaoFinal: null,
        dataHoraLiberacaoInicial: null,
        dataHoraLiberacaoFinal: null,
        dataPagamentoInicial: null,
        dataPagamentoFinal: null
    }

    responsavelSerivce: FuncionarioService = new FuncionarioService();
    responsaveis: Funcionario[] = [];
    onSearchResponsavel: any = null;
    isResponsavelLoading: boolean = false;

    equipamentoService: EquipamentoService = new EquipamentoService();
    equipamentos: Equipamento[] = [];
    onSearchEquipamento: any = null;
    isEquipamentoLoading: boolean = false;

    fornecedorService: FornecedorService = new FornecedorService();
    fornecedores: Fornecedor[] = [];
    onSearchFornecedor: any = null;
    isFornecedorLoading: boolean = false;    

    itemClass(item: any){
        if(!item.responsavelId) 
            return 'itemIncompleto'; 
    }

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){

        if(isFiltro)
            this.options.page = 1;

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Responsavel, Fornecedor, Equipamento").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false
        });
    }

    @Watch('onSearchResponsavel')
    SearchResponsavel (val: string) {
        if (this.isResponsavelLoading) return;
        if (!val) return;
        this.isResponsavelLoading = true
        this.responsavelSerivce.AutoComplete(val).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isResponsavelLoading = false;
        });
    }

    @Watch('onSearchEquipamento')
    SearchEquipamento (val: string) {
        if (this.isEquipamentoLoading) return;
        if (!val) return;
        this.isEquipamentoLoading = true
        this.equipamentoService.AutoComplete(val).then(
            res => {
                this.equipamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isEquipamentoLoading = false;
        });
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isFornecedorLoading = false;
        });
    }

    mounted() {

    }

    AbrirDialogCadastro(item?: PedidoCompra){
        if(item){
            this.overlay = true;
            this.service.ObterPorId(item.id, "Responsavel, Fornecedor, Equipamento, Equipamentos.Equipamento.RegistroOperacional, Equipamentos.Equipamento.Equipamento, Equipamentos.Equipamento.Fornecedor").then(
                res=>{
                    this.item = new PedidoCompra(res.data);
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.overlay = false;
            })
        }
        else{
            this.item = new PedidoCompra();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    AbrirJanela(id: number, rota: string) {
        let routeLink = this.$router.resolve({ name: rota, params: { id: id.toString() } });
        window.open(routeLink.href, '_blank');
    }

    Excluir(item: RegistroOperacional){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }

}
